import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Navbar from '../components/sidenav'
import Editorsnotesound from '../sound/editorsnote.mp3'
import Footer from '../components/footer'



const Editorsnote = () => (
  <Layout>
    <SEO title="About" />
    <Navbar></Navbar>
    <div class="backHome">
      <a href="/">
        <small> ← Back to home</small>
      </a>
    </div>
    <h2>About the Women's Issue — A Conversation with the Editors</h2> <br /><br />
    <audio controls>
      <source src={Editorsnotesound} type="audio/mp3"></source>
    Your browser does not support the audio element.
    </audio>

    <p><strong>Owen Ojo</strong>: So Sabrina, how do you feel about the fact that it's February 4th and we've been working on this since—probably before this time last year basically?<br /></p>

    <p><strong>Sabrina Li</strong>: Yeah, it's honestly been so much. I didn't expect this to last an entire year, and it's been really interesting to see what's been going on in the world as we've been collecting these pieces: like #MeToo has happened, the Kavanaugh trials have happened, and Time's Up and all these big, very social-media-driven events have been happening all around us. And we're just collecting all this literature and art. It's interesting because it's been insular to this Harvard community up until now, but also we are interacting with these topics so much, and that has been an interesting dynamic. Also, it's important to note that the <i>Advocate</i> has in the past published issues that explored gender and gender dynamics. In 1973, in the midst of second-wave feminism, the Advocate published a special issue titled "Feminine Sensibility." The issue focused on asking questions, such as <i>What is feminine sensibility if it indeed exists? To what does it apply? How apparent is the writer's sex or personality and where do they overlap? When are they distinct?</i> And in 2000, the <i>Advocate</i> published the Men Issue—an attempt to subvert gender roles and objectify men through art and literature. Now, in 2019, with this <i>Women's Issue</i> we are building off the work of our predecessors but also resisting this tendency to view women solely in the context of men and to delve deeper into intersectionality and the blurred lines of gender. How are you feeling, though, Owen?<br /></p>

    <p><strong>Ojo</strong>: Yeah, I agree with you. It's a really weird thing to be in college in such a sociopolitically charged time. To be women on campus during times like these and women of color—I think it's a really important thing that we're doing, giving people the space to talk about these issues.</p>

    <p><strong>Li</strong>: I feel that the terms "women's fiction" or "women's terature" or "women's art" are such charged things, and a lot of time people reduce them to the idea that the art that's about women or that women make is just solely tied to their identities. You never say "men's art" or "men's terature." And we really wanted to explore, through this project, that women's art and terature doesn't necessarily have to be about women, or feminism isn't necessarily about women in the context of men. I think people, at times, whether they mean to or not, are reducing these ideas. Being able to take a moment and step back and see the nuances of what women's art and literature can be was something that was really important to us and driving us during this project.</p>

    <p><strong>Ojo</strong>: I totally agree with that. That's why I'm so happy that we opened this up to anyone who was interested who felt passionate enough to submit art or fiction or poetry or personal essay, because sexism isn't about one particular gender, two genders—all of this stuff is about everyone, and it's something that everyone should participate in and care about. Womanhood is definitely a central theme in this issue, but it's not the only theme, because womanhood does not exist in a vacuum.</p>

    <p><strong>Li</strong>: And it was so nice to see—as we were doing the application process for the editorial boards and gathering them together to start this project—the vast range of people who were interested in this. We made a point to phrase our publicity as "Anyone who feels passionate or interested in the idea of a women's issue should be encouraged to apply." And it was great to see all sorts of gender identities and people coming out to be a part of this project. And that made us so excited, and I think the pieces we have in this magazine really reflect that. Was there anything that excited you about the pieces that we're publishing?</p>

    <p><strong>Ojo</strong>: I was really excited by the variety. I love that people felt super bold about submitting, and some people were very much like, <i>I'm gonna talk about womanhood, I'm gonna talk about gender</i>, and that was great, and then some people were very much like, <i>I'm just gonna submit this, I have this, I like it, I'm just gonna see if it gets in, I guess</i>, and I loved that. But I was really drawn to a lot of the themes of community that many people discussed. For example, poems talked about a sort of sisterhood amongst other women, there were personal essays that talked about that as well, and it's just so positive. I think it's important that we just had so many people who were like, <i>Things are bad but I find hope through the fact that I'm united with other people in the struggle</i>,  or, <i>I had my mother, my grandmother, my sister here to support me,  or, I'm gonna reach out to fellow women or fellow siblings, etc.</i>, anyone who supported them. And so there was such a great awareness of support that was really refreshing to read, because I think I didn't expect that much positivity. Not that I expected exclusively negativity, but when I read things that are about this theme, a lot of it is struggle, and so that's what I expected when we looked through submissions. But there were many things that were about struggle, and then there were little moments of beauty in collectivity of some sort, or a conclusion that came to that. What about you?</p>

    <p><strong>Li</strong>: Yeah, I really enjoyed that too. I really enjoyed seeing intergenerational themes of women and what's passed and inherited among them and how they've come together as a collective. And I also thought it was really interesting how themes of violence, especially to the body, emerged. It wasn't necessarily as bleak as you would think in terms of women's bodies being constantly violated. There were some works that had violence as a backdrop and blurred the lines of who was being violated and who was doing the violating, and I thought that was really interesting, because I think in the media, and what I expected people thought when they heard the submission call for <i>The Women's Issue</i>, was that there would be just so many pieces focusing on violation and violence of men perpetuating this act on women. It was really interesting to see how some writers and artists complicated that, and really great to see how these contributors, on top of blurring these levels of violence, were also blurring lines of gender and lines of culture and assimilation and heritage and how we are able to track those things. I think, overall, what I really noticed were the different ways we inherit things through these kinds of violent acts as they are passed down, or what's passed along culturally and what isn't. And I thought that was a common theme throughout the works that I was interested in.</p>

    <p><strong>Ojo</strong>: I think this really marks a huge change in how the <i>Advocate</i> interacts with the rest of the Harvard community and how it will continue to become an even more inclusive and more open space for people who didn't think they belonged in a place like that. So this came out of desires to write about the #MeToo movement, but it's really interesting that we had to adapt a lot, because by the time we started to think that this was going to be a thing, the #MeToo movement wasn't such a hot topic on campus anymore. But the concept of gender discrimination and sexual violence and gender violence is obviously always relevant. So, how was it for you to make things as flexible as they could be in the political climate?</p>

    <p><strong>Li</strong>: I think that's a really good point. I think one thing that I was sort of wary of when we were doing this project and bringing it out to the public was that I didn't want it to be just a reaction to the sorts of movements that were going on. Like you see a lot in these Buzzfeed and Feminist Wire forums that say, "Oh, everything is all about women nowadays," talking about women, with all of these corporate brands putting on this feminist tone. Everything just seems very in the moment and commercialized, and when we were starting this project, we were aware of that and we really wanted to try to not have this be a hot button topic. Literature and art about women are things that have always preoccupied us. It is a timely subject, but it is something that has always been on our minds despite the events that have been going on. So, I think that's something that we've always been talking about with our board heads and our executive team—that this isn't just a one-and-go kind of thing, this is something that is being continually thought about and talked about and isn't just a fad.</p>

    <p><strong>Ojo</strong>: I love the fact that you're trying to have this as a permanent thing for the <i>Advocate</i> because this is a really big thing for the organization as well. I love that you want this special fifth issue series to have a different focus but still have the same function as a safe space for students all over campus and all over the world who care to talk about issues that mean something to them through art and literature.</p>

    <p><strong>Li</strong>: Yeah, that's why I was so happy to do this with you Owen. As you were saying before, art and literature aren't happening in a vacuum, and one of my favorite writers, Yiyun Li, says that politics is like rain; it's like the weather—you just li  ve in it. And a lot of times in America, she was mentioning that we separate the personal and the political and it's kind of a futile thing. And that's something that I think not just the <i>Advocate</i> but other literary and art journals, they at times have a tendency to look at literature in this isolated space, and because of this, we get into issues of diversity whether it's race or socioeconomic status or gender or sexuality or disability or anything like that. And I think that, explicitly through specific art and literary platforms, we can increase the range of dialogue that we're able to take part in, and I think that's really important because literature and art shouldn't just be for a specific group. And I'm so glad that we're not only tackling that with topics--I was also so happy that we both made such a huge effort to really expand the genres that we're representing in this issue. I think all of these expansions and this whole thing of accessibility and creating new spaces and creating new platforms for dialogues is something that I'm so excited about, and I'm even more excited that I got to do with you.</p>

    <p><strong>Ojo</strong>: Oh, I'm so happy that I go to do it with you, especially before I'm graduating. I think through this I've also learned so much more about how relentlessly determined you are to create great things and to do things that nobody has ever done before. I wouldn't want to be doing this with anyone else.</p>

    <p><strong>Li</strong>: You inspire me so much, and I say this a lot but the reason I'm so passionate about creating these spaces and engaging in literature and art in such active ways on campus is really because of you and the way we talk about literature and art. I'm so proud of the work that we've done on this, and I'm so proud of the board that we have and all the great content work that they've done. It's really been a huge effort that I didn't realize was going to take over the entire course of the year, but I'm so glad that it has, because I think that we've really taken the time to talk through these pieces; we have so many great podcasts and interviews and conversations and new dialogues on top of just the final drafts of the pieces we have on the website. And I think it's just a really great breeding ground for new conversation and dialogue to take place, and I'm really proud and excited about it, and I couldn't have done it at all without you.</p>

    <p><strong>Ojo</strong>: I'm so grateful to be doing it with you, and I think it's really cool because this whole thing—the concept of it—is in a lot of ways an extension of our everyday conversations that we'd just be having with each other about our own lives and our own places as writers in the world and on campus. And I feel like the two of us are people who really use writing and art as means of conveying things that are harder to express with just literal words, or we use them as spaces for discourse or to engage in discourse. And that's why I love <i>The Women's Issue</i>, because it's one of those things where it's proof that there are so many ways to be politically engaged and socially engaged. It's important to just welcome anyone who's interested and anyone who wants to do art and literature, even if maybe they're not super interested in politics or maybe they didn't talk about #MeToo all the time with their friends. We've made the issue a very judgment-free space, and as you were saying before, it's really hard to separate your identity from politics, and it's always hard to separate your life from society or societal influence or context.</p>

    <p><strong>Li</strong>: I completely agree with you, and I think especially in college, in this environment where we're all living together and it's very intense and in this bubble, there is a certain pressure, as you were saying, to be politically minded or to be engaged in the world in a very specific way. I think through this issue we're trying to open another avenue where you can be thinking about these questions and caring about these things without necessarily going to a march or a protest--and while those are definitely important and have their own value, there are so many different ways people end up finding the topics and grappling with their identities, and to be able to be another platform where people can express those thoughts and have those questions, whether they're answerable or not, is very important. I'm so happy that this place was able to be that for the writers and artists who contributed to this magazine.</p>

    <p><strong>Ojo</strong>: Same. For this as well, we want to thank, in no particular order, Emma Stone, Cassandra Luca, Eva Rosenfeld, Nicole Araya, Sophia Mautz, and Marie Konopacki.</p>

    <p><strong>Li</strong>: We are so thankful to our board heads who made all of this possible and to all of our board members. It's been such a great experience having worked with you, and we're so fortunate and lucky that you wanted to do this with all of us. We also couldn't have done this without the support from Harvard's Office for the Arts and Harvard's Women's Center, and a huge thank you to Laura van den Berg for being the faculty advisor on this project. We really could not have done it without you.</p>

    <p><strong>Ojo</strong>: And also a big thank you to all of the <i>Advocate</i> alumni who were so enthusiastic in supporting us in this project.</p>

    <div class="linebreak" />
    <Footer />

  </Layout>
)

export default Editorsnote
